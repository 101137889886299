import React, { ReactElement, useState, useEffect } from "react";
import CustomDatePicker from "../../../../shared/components/form-elements/custom-datepicker";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Api from "../../../../shared/network/axiosClients";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  phoneNumber: string; // Pass the phone number as a prop
}

const Sidebar = ({ isOpen, toggleSidebar, phoneNumber: propPhoneNumber }: SidebarProps): ReactElement => {
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string | null>(propPhoneNumber);
  const { search: queryParams } = useLocation();

  // Function to get the phone number from the query string
  const getContactNumberFromQueryParams = (queryString: string): string | null => {
    const params = new URLSearchParams(queryString);
    return params.get("contactNumber") || null;
  };

  // Use effect to update phone number from query params on mount
  useEffect(() => {
    const contactNumber = getContactNumberFromQueryParams(queryParams);
    if (contactNumber) {
      setPhoneNumber(contactNumber); // Set phone number from query params
    }
  }, [queryParams]);

  // For debugging, log the phone number being used
  console.log("Phone number:", phoneNumber);

  const handleConfirm = async () => {
    if (!selectedDate || !selectedTime) {
      // alert("Please select both date and time.");
      setErrorMessage("Please select both date and time.");
      return;
    }

    setIsLoading(true);
    setErrorMessage(""); // Reset error message on successful selection

    const formattedDate = selectedDate.toLocaleDateString("en-GB"); // Format the date as 'dd/MM/yyyy'

    try {
      await Api.post("web/quick-call", {
        date: formattedDate,
        time: selectedTime,
        phoneNumber
      });

      setFormSubmitted(true); // Display the thank you message after success
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (formSubmitted) {
    return (
      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebarContent">
          {/* <button onClick={toggleSidebar}>x</button> */}
          <div className="container agentForm">
            <img
              src="/assets/img/thankyouAgent.svg"
              alt="Thankyou Agent"
            />
            <h2 className="mt-3">Your call is scheduled successfully!</h2>
            <p>Our takaful specialist will contact you</p>
            <div className="d-flex justify-content-center">
              <p> {selectedDate ? selectedDate.toLocaleDateString("en-GB") : "N/A"}</p>
              <p> {selectedTime || "N/A"}</p>

              <button
                onClick={toggleSidebar}
                className="confirmbut"
              >
                Okay,Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const oneYearFutureDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebarContent">
        <button
          className="crossIconForm"
          onClick={toggleSidebar}
        >
          x
        </button>
        <div className="container-fluid ps-0 callForm">
          <div className="row">
            <div className="col-md-6 pt-5 advisorSchedule">
              <h5 className="sidebarHeading">Ask our advisor for right plans and discounts</h5>
              <p className="sidebarCaption">Pick your preferred time</p>
            </div>
            <div className="col-md-6 scheduleHeaderImg">
              <img
                src="/assets/img/scheduleacallimg.svg"
                alt="Schedule"
              />
            </div>
          </div>
        </div>
        <div className="padding-pa">
          <div className="container">
            <div className="row">
              <h3 className="sidebarHeading mt-5 mb-3">Select Day</h3>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${
                    selectedDate && selectedDate.toDateString() === new Date().toDateString() && !isCustomDateSelected ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedDate(new Date());
                    setSelectedTime("Today");
                    setIsCustomDateSelected(false);
                  }}
                >
                  Today
                </button>
              </div>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${
                    selectedDate && selectedDate.toDateString() === new Date(Date.now() + 86400000).toDateString() && !isCustomDateSelected
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    setSelectedDate(new Date(Date.now() + 86400000));
                    setSelectedTime("Tomorrow");
                    setIsCustomDateSelected(false);
                  }}
                >
                  Tomorrow
                </button>
              </div>
              <div className="col-4">
                <button
                  className={`w-100 btn-scheduleaCall ${isCustomDateSelected ? "active" : ""}`}
                  onClick={() => {
                    setIsCustomDateSelected(true);
                    setSelectedTime("Custom Date");
                  }}
                >
                  Select Date
                </button>
              </div>
              <div className="col-md-12 mt-3">
                {isCustomDateSelected && (
                  <CustomDatePicker
                    showIcon={true}
                    label="Select Date"
                    onChange={(date: Date) => {
                      setSelectedDate(date);
                      setIsCustomDateSelected(true);
                      setSelectedTime("Custom Date");
                    }}
                    value={selectedDate}
                    minDate={`${new Date()}`}
                    maxDate={oneYearFutureDate}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <h3 className="sidebarHeading mt-5 mb-3">Select Time</h3>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "11 AM-12 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("11 AM-12 PM")}
                >
                  11 AM-12 PM
                </button>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "12 PM-1 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("12 PM-1 PM")}
                >
                  12 PM-1 PM
                </button>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "1 PM-2 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("1 PM-2 PM")}
                >
                  1 PM-2 PM
                </button>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "2 PM-3 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("2 PM-3 PM")}
                >
                  2 PM-3 PM
                </button>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "3 PM-4 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("3 PM-4 PM")}
                >
                  3 PM-4 PM
                </button>
              </div>
              <div className="col-6 col-md-4 mb-2">
                <button
                  className={`w-100 btn-scheduleaCall ${selectedTime === "4 PM-5 PM" ? "active" : ""}`}
                  onClick={() => setSelectedTime("4 PM-5 PM")}
                >
                  4 PM-5 PM
                </button>
              </div>

              {errorMessage && <div className="error-message">{errorMessage}</div>}

              <div className="col-md-12 mt-3">
                <button
                  className="confirmbut"
                  onClick={handleConfirm}
                  disabled={isLoading}
                >
                  {isLoading ? "Submitting..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
