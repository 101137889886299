import { IAlphaNumeric, IDropdownOption, QueryParamType, QueryParams } from "../interface";
import { PlanTypes, RateModeTypes, UserTypes, baseUrlV1 } from "../constant";
import {
  ICartDetailInterface,
  IDropdown,
  IHealthCartPlans,
  IHealthFamilyDetails,
  IHealthFilter,
  IHealthFilterFormVales,
  IHealthPricing,
  IListOfValues,
  ISelectOptionNonGroup
} from "../../modules/health/utils/interfaces";
import { INestedDocumentArray, IReviewDocuments } from "../../modules/health/utils/interfaces/order-details";
import { URLSearchParamsInit } from "react-router-dom";

export const capitalizeCase = (str: string): string => {
  if (!str) return ""; // Check if str is undefined or empty
  return str[0]?.toUpperCase() + str.slice(1);
};
export const generateAgeOptions = (start: number, end: number): IDropdownOption[] => {
  const ageOptions = [];
  for (let i = start; i <= end; i++) {
    ageOptions.push({ label: `${i}`, value: `${i}` });
  }
  return ageOptions;
};
export const copyToClipboard = (text: string): void => {
  navigator.clipboard?.writeText(text);
};
export const getAPIUrl = (endPoint: string, queryParams?: QueryParamType): string => {
  if (!endPoint) return "";
  if (!queryParams) return endPoint;
  let queryString = "";
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value) {
      queryString += `${queryString ? "&" : ""}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }
  const apiUrl = `${endPoint}?${queryString}`;
  return apiUrl;
};
export const getTotalPages = (limit: number, totalElemets: number): number => {
  const totalPages = Math.ceil(totalElemets / limit);
  return totalPages;
};

export const getSelectedValue = (value: number | number[] | string | boolean, data: IDropdownOption[]): IDropdownOption | undefined => {
  return data?.find((c: IDropdownOption) => c?.value === String(value));
};

export const userRepresentableDetails = (
  details: IHealthFamilyDetails | undefined,
  isParent: boolean,
  t: Function,
  seperator = ", "
): {
  planType: string;
  value: string;
} => {
  if (!details) return { planType: "", value: "" };
  const addSeperatorIfValueExist = (value: string, toBeAdd: string) => {
    if (value) {
      return `${value}${seperator}${toBeAdd}`;
    }
    return toBeAdd;
  };

  let planType = "";

  if (!isParent) {
    let nonParentDetail = "";

    if (details?.self) {
      nonParentDetail += t("health_landing_user_details_label_self");
      planType = PlanTypes.SELF;
    }
    if (details?.spouse) {
      nonParentDetail = addSeperatorIfValueExist(nonParentDetail, t("health_landing_user_details_label_spouse"));
      planType = PlanTypes.FAMILY;
    }
    if (details?.children?.length) {
      nonParentDetail = addSeperatorIfValueExist(nonParentDetail, `${t("health_landing_user_details_label_child")} (${details?.children?.length})`);
      planType = PlanTypes.FAMILY;
    }
    return { planType, value: nonParentDetail };
  }

  let parentDetail = "";
  if (details?.father) {
    parentDetail = addSeperatorIfValueExist(parentDetail, t("health_landing_user_details_label_father"));
    planType = PlanTypes.PARENT;
  }
  if (details?.mother) {
    parentDetail = addSeperatorIfValueExist(parentDetail, t("health_landing_user_details_label_mother"));
    planType = PlanTypes.PARENT;
  }

  return { planType, value: parentDetail };
};

export const nthNumber = (value: number): string => {
  return value > 0 ? ["th", "st", "nd", "rd"][(value > 3 && value < 21) || value % 10 > 3 ? 0 : value % 10] : "";
};

export const formatDOB = (value: string): string => {
  const date = new Date(value);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day}${nthNumber(day)} ${month} ${year}`;
};
export const formatPhoneNumber = (input?: string): string => {
  let formattedNumber = "";
  // Remove leading '+' and any non-digit characters
  if (input) {
    const digitsOnly = input.replace(/[^\d]/g, "");

    // Add the desired formatting
    formattedNumber = `+${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2, 5)} ${digitsOnly.slice(5)}`;
  }

  return formattedNumber;
};

export const appendQueryInPath = (filters: IHealthFilterFormVales): IHealthFilter | URLSearchParamsInit => {
  const { coverageIds, serviceIds, planType, takafulProviderIds, coverageLimit, payMonthly, sortOrderFilter, minPrice, maxPrice } = filters;

  const queryParams = {
    rateModeType: payMonthly ? RateModeTypes.MONTHLY : RateModeTypes.ANNUAL
  } as IHealthFilter;

  if (coverageLimit) {
    queryParams.coverageLimit = coverageLimit;
  }
  if (minPrice) {
    queryParams.minPrice = Number(minPrice);
  }
  if (maxPrice) {
    queryParams.maxPrice = Number(maxPrice);
  }
  if (planType) {
    queryParams.planType = planType;
  }
  if (coverageIds?.length) {
    queryParams.coverageIds = coverageIds.join(",");
  }
  if (serviceIds?.length) {
    queryParams.serviceIds = serviceIds.join(",");
  }
  if (takafulProviderIds?.length) {
    queryParams.takafulProviderIds = takafulProviderIds;
  }

  if (sortOrderFilter) {
    const [sortBy, sortOrder] = (sortOrderFilter as string).split("_");
    queryParams.sortBy = sortBy;
    queryParams.sortOrder = sortOrder;
  }

  return queryParams;
};
export const getFileFormatMessage = (fileFormats: string[]): string => {
  const formattedFileFormats = fileFormats.map((format: string) => format.toUpperCase());
  return formattedFileFormats.join(", ");
};

export const dropDownPayload = (
  payload: IDropdown[] | IListOfValues[],
  valueKey: keyof (IListOfValues | IDropdown) = "id",
  extraKey?: keyof (IListOfValues | IDropdown)
): ISelectOptionNonGroup[] | [] => {
  if (payload?.length) {
    return payload?.map((item: IDropdown | IListOfValues) => ({
      label: item.value,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item[valueKey],
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] })
    }));
  } else {
    return [];
  }
};

export const isUserCustomer = (userType: string): boolean => {
  return userType === UserTypes.CUSTOMER || !userType;
};
export const getFormatedDiscountValues = (min: string | null | number, max: string | null | number): string => {
  if ((min == null || min == 0) && (max == null || max == 0)) {
    return "-";
  } else if (min === null) {
    return `${max} %`;
  } else {
    return `${min} % - ${max} %`;
  }
};
export const getRateModeType = (isMonthly: boolean): string => {
  return isMonthly ? RateModeTypes.MONTHLY : RateModeTypes.ANNUAL;
};
export const getRangeAndPrice = (isCustomer: boolean, pricing: IHealthPricing): string => {
  let priceShowToRange = String(pricing?.listingPriceMax);
  const minPrice = pricing?.listingPriceMin;
  if (!isCustomer && minPrice) {
    priceShowToRange = `${minPrice} - ${priceShowToRange}`;
  }

  return priceShowToRange;
};
export const getMonthRangeAndPrice = (isCustomer: boolean, isFirstMonth: boolean, pricing: IHealthPricing): IAlphaNumeric => {
  const { firstMonthInstallmentMax, firstMonthInstallmentMin, remainingMonthInstallmentMax, remainingMonthInstallmentMin } = pricing;

  let priceShowToRange = isFirstMonth ? firstMonthInstallmentMax : remainingMonthInstallmentMax;
  const minPrice = isFirstMonth ? firstMonthInstallmentMin : remainingMonthInstallmentMin;

  if (!isCustomer && minPrice) {
    priceShowToRange = `${minPrice} - ${priceShowToRange}`;
  }

  return priceShowToRange;
};

export const isClientSideRendered = (): boolean => {
  const isClient = typeof window !== "undefined";
  return isClient;
};
export const navigateToUrl = (path: string): void => {
  window.location.href = `${baseUrlV1}${path}`;
};
export const isPlanSelected = (planID: number, activePlanType: keyof typeof cart, cart: IHealthCartPlans): boolean => {
  if (!planID || !activePlanType || !cart || !cart?.[activePlanType]) return false;

  const cartPlanID = cart[activePlanType]?.plan.id;
  const isCurrentPlanSelected = cartPlanID === planID;
  return isCurrentPlanSelected;
};
export const sortDocument = (data: IReviewDocuments[]): IReviewDocuments[] => {
  const categoryMap: { [key: string]: IReviewDocuments[] } = {};

  data.forEach((obj: IReviewDocuments) => {
    const { category } = obj;

    if (!categoryMap[category as keyof typeof categoryMap]) {
      categoryMap[category as keyof typeof categoryMap] = [];
    }
    categoryMap[category as keyof typeof categoryMap].push(obj);
  });

  const sortedResult: IReviewDocuments[] = [];
  const categoryOrder = ["Self", "Spouse", "Child", "Father", "Mother"];
  categoryOrder.forEach(category => {
    if (categoryMap[category]) {
      sortedResult.push(...categoryMap[category]);
    }
  });
  return sortedResult;
};

export const sortRequiredDocument = (data: ICartDetailInterface[]): INestedDocumentArray => {
  const categoryMap: { [key: string]: (ICartDetailInterface | string)[] } = {};

  // Collect documents by category
  data.forEach((obj: ICartDetailInterface) => {
    const category = obj.category || capitalizeCase(obj.relation.key);
    if (!categoryMap[category as keyof typeof categoryMap]) {
      categoryMap[category as keyof typeof categoryMap] = [];
    }
    categoryMap[category as keyof typeof categoryMap].push(obj);
  });

  const sortedResult: string[][] = [];
  const categoryOrder = [
    ["Self", "Spouse", "Child"],
    ["Father", "Mother"]
  ];

  // Build sortedResult maintaining the outer array structure
  categoryOrder.forEach(order => {
    const sortedCategoryArray = order.reduce((acc, category) => {
      return acc.concat((categoryMap[category] as string[]) || []);
    }, [] as string[]);
    if (sortedCategoryArray?.length) sortedResult.push(sortedCategoryArray);
  });

  return sortedResult as unknown as INestedDocumentArray;
};
export const sortRequiredDocumentReviewDetails = (data: ICartDetailInterface[][]): INestedDocumentArray => {
  const categoryMap: { [key: string]: (ICartDetailInterface | string)[] } = {};

  // Collect documents by category
  data.forEach(categoryArray => {
    categoryArray.forEach((obj: ICartDetailInterface) => {
      const category = obj.category || capitalizeCase(obj.relation?.key);

      if (!categoryMap[category as keyof typeof categoryMap]) {
        categoryMap[category as keyof typeof categoryMap] = [];
      }
      categoryMap[category as keyof typeof categoryMap].push(obj);
    });
  });

  const sortedResult: string[][] = [];
  const categoryOrder = [
    ["Self", "Spouse", "Child"],
    ["Father", "Mother"]
  ];

  // Build sortedResult maintaining the outer array structure
  categoryOrder.forEach(order => {
    const sortedCategoryArray = order.reduce((acc, category) => {
      return acc.concat((categoryMap[category] as string[]) || []);
    }, [] as string[]);
    if (sortedCategoryArray?.length) sortedResult.push(sortedCategoryArray);
  });

  return sortedResult as unknown as INestedDocumentArray;
};
export const formattedCNIC = (value: IAlphaNumeric): string => {
  const numericValue = (value as string)?.replace(/\D/g, "");
  let formattedValue = "";
  for (let i = 0; i < numericValue?.length; i++) {
    if (i === 5 || i === 12) {
      formattedValue += "-";
    }
    formattedValue += numericValue[i];
  }
  return formattedValue;
};

export const getUrlWithQueryParams = (url: string, queryParams?: QueryParams): string => {
  if (!url) return "";
  if (!queryParams) return url;

  let queryString = "";

  for (const key in queryParams) {
    const value = queryParams[key];
    if (value) {
      queryString += `${queryString ? "&" : ""}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }

  const urlWithQueryParams = `${url}?${queryString}`;
  return urlWithQueryParams;
};

export const dobMinimumDateValidation = (years: number): string => {
  const currentDate = new Date();
  currentDate.setFullYear(currentDate.getFullYear() - years);
  return currentDate.toISOString().split("T")[0];
};

export const isMobile = (): boolean => {
  if (typeof window !== "undefined") {
    // Check if the window object is available (client-side)
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  // Default to false if window object is not available (server-side)
  return false;
};

export const createQueryString = (queryObject: object): string => {
  const queryString = Object.keys(queryObject)
    .map(key => `${key}=${encodeURIComponent(queryObject[key as keyof typeof queryObject])}`)
    .join("&");
  if (queryString) {
    return `?${queryString}`;
  }
  return "";
};

export const getDateDistanceinYears = (years: number, isFutureDate: boolean): string => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const date = new Date().getDate() + 1;
  return new Date(year - (!isFutureDate ? years : -years), month, date).toISOString().split("T")[0];
};

export const getISODateWithoutTime = (date: Date): string | null => {
  if (!date) return null;
  const isFormattedDate = typeof date === "string";
  if (isFormattedDate) return date;

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate() + 1;
  return new Date(year, month, day).toISOString().split("T")[0];
};
